import * as Sentry from "@sentry/gatsby";

// only initialize sentry in production
Sentry.init({
  dsn: process.env.NODE_ENV === "production" ? "https://4aee10a270aa46c5b30d50db18a33b9c@o4504480087932928.ingest.sentry.io/4504484564172800": "",
  tracesSampleRate: 0.5, // Adjust this value in production
  debug: process.env.NODE_ENV !== "production",
//   beforeSend(event) {
//     // Modify the event here
//     if (event.user) {
//       // Don't send user's email address
//       delete event.user.email;
//     }
//     return event;
//   },
});